import { Component } from '@angular/core';
import {NavigationCancel, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {NgClass} from "@angular/common";
import {CustomizerSettingsComponent} from "./customizer-settings/customizer-settings.component";
import {ToggleService} from "./common/sidebar/toggle.service";
import {CustomizerSettingsService} from "./customizer-settings/customizer-settings.service";
import {filter} from "rxjs";



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgClass, CustomizerSettingsComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Wizodia -Admin';
  routerSubscription: any;
  location: any;

  // isSidebarToggled
  isSidebarToggled = false;

  // isToggled
  isToggled = false;

  constructor(
    public router: Router,
    private toggleService: ToggleService,
    public themeService: CustomizerSettingsService
  ) {
    this.toggleService.isSidebarToggled$.subscribe(isSidebarToggled => {
      this.isSidebarToggled = isSidebarToggled;
    });
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
  }

  // ngOnInit
  ngOnInit(){
    this.recallJsFuntions();
  }

  // recallJsFuntions
  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }

  // Dark Mode
  toggleTheme() {
    this.themeService.toggleTheme();
  }

  // Sidebar Dark
  toggleSidebarTheme() {
    this.themeService.toggleSidebarTheme();
  }

  // Right Sidebar
  toggleRightSidebarTheme() {
    this.themeService.toggleRightSidebarTheme();
  }

  // Hide Sidebar
  toggleHideSidebarTheme() {
    this.themeService.toggleHideSidebarTheme();
  }

  // Header Dark Mode
  toggleHeaderTheme() {
    this.themeService.toggleHeaderTheme();
  }

  // Card Border
  toggleCardBorderTheme() {
    this.themeService.toggleCardBorderTheme();
  }

  // Card Border Radius
  toggleCardBorderRadiusTheme() {
    this.themeService.toggleCardBorderRadiusTheme();
  }

  // RTL Mode
  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }
}

import {ApplicationConfig, importProvidersFrom, LOCALE_ID} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {HttpClientModule, provideHttpClient, withFetch} from "@angular/common/http";
import {DatePipe, registerLocaleData} from "@angular/common";
import {MAT_DATE_LOCALE, provideNativeDateAdapter} from "@angular/material/core";
import {provideEnvironmentNgxMask} from "ngx-mask";
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from "@angular/material/core";
import {NgxPermissionsModule} from "ngx-permissions";
import {DetecteconnexionetatService} from "./shared/services/detecteconnexionetat.service";
import {MessageService} from "primeng/api";
import {NgCircleProgressModule} from "ng-circle-progress";
import localeFr from '@angular/common/locales/fr';


registerLocaleData(localeFr);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withFetch()),
    DatePipe,
    importProvidersFrom(NgxPermissionsModule.forRoot()),
    provideNativeDateAdapter(),
    provideEnvironmentNgxMask(),
    // {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
    //DatePipe,
    DetecteconnexionetatService,
    MessageService,
    HttpClientModule,
    NgCircleProgressModule,

    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' }
  ]
};

import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DetecteconnexionetatService {

  connectionStatus$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private ngZone: NgZone
  ) {
    this.initConnectivityCheck();
  }

  private initConnectivityCheck(): void {
    window.addEventListener('online', () => {
      this.ngZone.run(() => {
        this.connectionStatus$.next(true);
      });
    });

    window.addEventListener('offline', () => {
      this.ngZone.run(() => {
        this.connectionStatus$.next(false);
      });
    });
  }
}
